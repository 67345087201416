import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__inner'>
          <div className='footer__inner-logo'>Hommest</div>
          <div className='footer__inner-con'>
            <ul>
              <li>
                <NavLink to='/contact'>Contact Us</NavLink>
              </li>
              <li>
                <NavLink to='/terms-use'>Terms of Use</NavLink>
              </li>
              <li>
                <NavLink to='/privacy'>Privacy</NavLink>
              </li>
            </ul>
            <div className='footer__inner-info'>
              <p>
                Discover a revolutionary way to manage your home tasks with
                "Husband for an Hour." Our dedicated professionals assist with
                repairs, organizing, and errands, making home life stress-free
                and convenient.
              </p>
              <a>
                1000949216 ONTARIO INC. 775 Steeles Avenue West, Unit 1103,
                North York, Ontario, M2R2S8, Canada
              </a>
              <a href='tel:14374495064'>+1 (437) 449 50 64</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
